export const getItemFromStorage = key => {
  try {
    return (
      window.localStorage.getItem(key) || window.sessionStorage.getItem(key)
    );
  } catch (error) {
    throw error;
  }
};

export const getItemFromSessionStorage = key => {
  try {
    return window.sessionStorage.getItem(key);
  } catch (error) {
    throw error;
  }
};

export const saveItemInStorage = (key, value, keepLogged) => {
  try {
    key = typeof key === "string" ? key : JSON.stringify(key);
    if (keepLogged) {
      window.localStorage.setItem(key, value);
      window.sessionStorage.removeItem(key);
    } else {
      window.sessionStorage.setItem(key, value);
      window.localStorage.removeItem(key);
    }
  } catch (error) {
    throw error;
  }
};

export const removeItemFromStorage = key => {
  window.localStorage.removeItem(key);
  window.sessionStorage.removeItem(key);
};

export const hasToken = () => {
  return !!getItemFromStorage('AUTH_TOKEN');
};

export const tokenStillValid = () => {
  if (hasToken()) {
    const expireTime = getItemFromStorage("EXPIRE_LOGIN");
    return expireTime > new Date().getTime();
  }
};
