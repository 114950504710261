import { Alert, Button, Divider, Form, Input } from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { getAccess, login } from "../../../api/login";
import logo from "../../../assets/images/logo.png";
import "./Login.scss";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = await login(values.email, values.password);
      setLoading(false);
      const access = await getAccess(data?.user?.id);
      const selected = access.results[0];
      const sellerData = { id: selected.seller.id, display_name: selected.seller.display_name };
      localStorage.setItem("seller", JSON.stringify(sellerData));
      navigate("/");
    } catch (error) {
      console.log(error.message.detail);
      setLoading(false);

      if (
        error.status === 401 &&
        error.message.detail === "No active account found with the given credentials"
      ) {
        setLoginError("Essa conta ainda não possui acesso a vendedores.");
      } else {
        setLoginError("Falha ao fazer login. Verifique suas credenciais.");
      }
    }
  };

  const handleAlertClose = () => {
    setLoginError(null);
  };

  return (
    <div className={"Login"}>
      <Helmet>
        <title>Clap Tickets - Login</title>
      </Helmet>
      <div className={"form-container"}>
        <div className={"logo"}>
          <img src={logo} alt="Logo" />
        </div>
        <Form
          name="normal_login"
          layout="vertical"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            label="Login"
            rules={[
              { required: true, message: "Por favor, insira seu usuário!" },
              { type: "text", message: "usuário inválido!" },
            ]}
          >
            <Input
              placeholder="Email"
              size="small"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Senha"
            rules={[
              { required: true, message: "Por favor, insira sua senha!" },
            ]}
          >
            <Input.Password
              type="password"
              hoverBorderColor="#FFFFFFF"
              placeholder="Senha"
              size="small"
            />
          </Form.Item>
          {loginError && (
            <Alert
              message={loginError}
              type="error"
              closable
              onClose={handleAlertClose}
              style={{ marginBottom: 16 }}
            />
          )}
          <Form.Item>
            <div className="container-buttons">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
                size="large"
              >
                Fazer login
              </Button>
              <Button
                type="link"
                onClick={() => navigate("/recovery")}
                size="small"
              >
                Esqueci minha senha
              </Button>
            </div>
          </Form.Item>
        </Form>
        <Divider />
        <div className={"register"}>
          <Button
            type="text"
            size="small"
          >
            Não possui uma conta?
          </Button>
          <Button size="small">
            Quero me cadastrar
          </Button>
        </div>
      </div>
      <div className={"background"}>
        <span>Com Clap, seu ingresso está na palma da mão</span>
      </div>
    </div>
  );
};

export default Login;
