import { DeleteFilled, DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Select, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import './AddQuestions.scss';

const { Option } = Select;

const AddQuestions = ({ starterQuestionList, setStarterQuestionList }) => {
  const [listOptions, setListOptions] = useState({});
  const [valueInput, setValueInput] = useState({});

  useEffect(() => {
    const initialOptions = {};
    starterQuestionList.forEach((question, index) => {
      if (question.values) {
        initialOptions[index] = question.values;
      }
    });
    setListOptions(initialOptions);
  }, [starterQuestionList]);

  const removeOption = (name, index) => {
    const updatedOptions = [...(listOptions[index] || [])];
    const optionIndex = updatedOptions.indexOf(name);
    if (optionIndex > -1) {
      updatedOptions.splice(optionIndex, 1);
      setListOptions({ ...listOptions, [index]: updatedOptions });
      handleChangeQuestions(updatedOptions, index, "values");
    }
  };

  const handleChangeQuestions = (value, index, keyName) => {
    // Cria uma cópia do starterQuestionList
    const questionsCopy = starterQuestionList.map((q) => ({ ...q })); // Clonando objetos para evitar referências

    // Atualiza o valor da pergunta no índice correto
    if (questionsCopy[index]) {
      questionsCopy[index][keyName] = value;
    }

    // Atualiza o estado com a lista de perguntas modificada
    setStarterQuestionList(questionsCopy);
  };

  const handleCreateOption = (value, index) => {
    if (value.trim().length < 5) {
      message.warning("O mínimo é 5 caracteres");
      return;
    }

    const updatedOptions = [...(listOptions[index] || []), value.trim()];
    setListOptions({ ...listOptions, [index]: updatedOptions });
    handleChangeQuestions(updatedOptions, index, "values");
    setValueInput({ ...valueInput, [index]: "" });
  };

  const handleDeleteQuestion = (index) => {
    const updatedList = starterQuestionList.filter((_, idx) => idx !== index);
    setStarterQuestionList(updatedList);
  };

  return (
    <div style={{ width: "100%" }}>
      {starterQuestionList.map((question, index) => (
        <div style={{ margin: "40px 0" }} key={index}>
          <Card
            style={{
              marginTop: "30px",
              padding: 0,
              alignItems: "flex-end",
              borderRadius: "0px",
              border: "unset"
            }}
          >
            <Form.Item layout='vertical' label="Pergunta" style={{ width: "100%", flexDirection: "column" }}>
              <Input
                type="text"
                value={question.prompt || ""} // Certifique-se de que este valor é o correto
                onChange={(e) => handleChangeQuestions(e.target.value, index, "prompt")} // Chame a função com o índice correto
              />
            </Form.Item>
            <div className="select-type">
              <Form.Item layout='vertical' label="Tipo de resposta" style={{ width: 250, marginRight: 24 }}>
                <Select
                  required={true}
                  style={{ height: 40 }}
                  placeholder="Tipo de resposta"
                  value={question.type || ""} // Use o valor correto aqui
                  onChange={(value) => handleChangeQuestions(value, index, "type")} // Certifique-se de que está chamando com o índice certo
                >
                  <Option value="STRING">Texto</Option>
                  <Option value="INTEGER">Numérico</Option>
                  <Option value="DATE">Data</Option>
                  <Option value="EMAIL">E-Mail</Option>
                  <Option value="PHONE">Telefone</Option>
                  <Option value="CPF">CPF</Option>
                  <Option value="LIST">LISTA</Option>
                  <Option value="RADIO">SELECT</Option>
                </Select>
              </Form.Item>
              <Button
                className='delete-button'
                danger
                icon={<DeleteFilled />}
                onClick={() => handleDeleteQuestion(index)} // Excluir a pergunta correspondente
              />
            </div>
          </Card>
          {(question.type === "LIST" || question.type === "RADIO") && (
            <div style={{ borderRadius: "0 0 20px 20px", padding: 20, border: "1px solid #f0f0f0", borderTop: "0px" }}>
              <div style={{ maxWidth: 300 }}>
                <Input
                  style={{ maxWidth: 300 }}
                  value={valueInput[index] || ""}
                  type="text"
                  placeholder="Adicionar opção"
                  onChange={(e) => setValueInput({ ...valueInput, [index]: e.target.value })} // Mantendo o valor correto
                  onPressEnter={() => handleCreateOption(valueInput[index], index)}
                />
                <Button style={{ marginTop: "10px" }} type="primary" onClick={() => handleCreateOption(valueInput[index], index)}>
                  Adicionar
                </Button>
              </div>
              <div style={{ width: "100%", flexWrap: "wrap", display: "flex", marginTop: 10 }}>
                {listOptions[index]?.map((item, key) => (
                  <div key={key} style={{ display: "flex", alignItems: "center", margin: "0 5px 3px 0", padding: "0px 10px", border: "1px solid #d9d9d9", borderRadius: 4, width: "fit-content" }}>
                    <span style={{ marginRight: 10 }}>{item}</span>
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => removeOption(item, index)}
                      style={{ color: "red" }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {index < starterQuestionList.length - 1 && <div className="divider-modal" />}
        </div>
      ))}
    </div>
  );
};

export default AddQuestions;
