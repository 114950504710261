import { Breadcrumb, Layout, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import React from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ReactComponent as Clock } from "../../../assets/images/dashboard/clock.svg";
import { ReactComponent as EmptyEvents } from "../../../assets/images/dashboard/logo-empty.svg";
import { ReactComponent as Money } from "../../../assets/images/dashboard/money.svg";
import { ReactComponent as Ticket } from "../../../assets/images/dashboard/ticket.svg";
import { ReactComponent as Computer } from "../../../assets/images/events/computer.svg";
import { ReactComponent as TwoPersons } from "../../../assets/images/events/two-person.svg";
import './Home.scss';

const Home = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const data = [
    { date: '30/Set', tickets: 0 },
    { date: '01/Out', tickets: 2 },
    { date: '02/Out', tickets: 10 },
    { date: '03/Out', tickets: 20 },
    { date: '04/Out', tickets: 15 },
    { date: '05/Out', tickets: 10 },
    { date: '06/Out', tickets: 60 },
    { date: '07/Out', tickets: 55 },
  ];

  const dataTwo = [
    { name: 'Happy hour', tickets: 40 },
    { name: 'BNI Suzano', tickets: 15 },
    { name: 'Palestra das Profissões', tickets: 60 },
    { name: 'Café com CEOs', tickets: 30 },
  ];


  return (
    <div className='Home' style={{ backgroundColor: "#f5f5f5" }}>
      <Helmet>
        <title>Clap Tickets - Dashboard</title>
      </Helmet>
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Content
          className="content-dashboard"
          style={{
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Breadcrumb
            className="breadcrumb-title"
          >
            <Breadcrumb.Item >Dashboard</Breadcrumb.Item>
          </Breadcrumb>

          {false && <div className="container-empty-events">
            <EmptyEvents />
            <h2>Sem dados ainda</h2>
            <p className="description-empty">
              Para visualizar seus dados, crie seu primeiro evento
            </p>
            <div className="container-empty-buttons">
              <span>Selecione o tipo de evento</span>
              <div className="empty-buttons">
                <button onClick={() => navigate("/eventos/criar", { state: { type: "Presencial" } })}><TwoPersons /> Presencial</button>
                <button onClick={() => navigate("/eventos/criar", { state: { type: "Online" } })}><Computer /> Online</button>
              </div>
            </div>
          </div>}
          <div className="container-dashboard">
            <div className="top-dashboard">
              <div className="card-top">
                <Money />
                <div className="container-infos">
                  <span>Receita total</span>
                  <p className="money">R$ 2.773,85</p>
                </div>
              </div>
              <div className="card-top">
                <Ticket />
                <div className="container-infos">
                  <span>Tickets vendidos</span>
                  <p className="ticket">155</p>
                </div>
              </div>
              <div className="card-top clock">
                <div className="container-infos clock">
                  <Clock />
                  <p className="clock-title">15H</p>
                </div>
                <div className="container-infos">
                  <span>Horário de pico de vendas</span>
                  <p className="clock-description">*50% dos seus ingressos foram <br /> vendidos neste horário</p>
                </div>
              </div>
            </div>
            <div className="body-dashboard">
              <div className="card-rechart">
                <p className="title-card">Ingressos vendidos por dia</p>
                <ResponsiveContainer width="95%" height={400}>
                  <LineChart
                    data={data}
                    margin={{ top: 10, right: 30, left: -30, bottom: 10 }} // Removendo a margem esquerda
                  >
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="tickets"
                      stroke="#FFA500"
                      strokeWidth={3}
                      dot={{ r: 6 }}
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>

              </div>
              <div className="card-rechart">
                <p className="title-card">Tickets vendidos por evento</p>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={dataTwo}
                    margin={{ top: 10, right: 30, left: 0, bottom: 10 }}
                  >
                    <CartesianGrid stroke="white" strokeDasharray="0" />
                    <XAxis dataKey="name" padding={{ left: 30, right: 30 }} /> {/* Adicionando padding ao eixo X */}
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="tickets" fill="#0047AB" barSize={Math.max(20, window.innerWidth / 50)} /> {/* Controlando a largura das barras */}
                  </BarChart>
                </ResponsiveContainer>

              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  )
}

export default Home
