import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Breadcrumb, Input, Layout, Select, Table, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Money } from "../../../assets/images/account/money.svg";
import { ReactComponent as MoneyDown } from "../../../assets/images/account/table-money-down.svg";
import { ReactComponent as MoneyUp } from "../../../assets/images/account/table-money-up.svg";
import { useIsMobileHook } from "../../../utils/WindowHooks";
import './Account.scss';

const Account = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const [habilityBalance, setHabilityBalance] = useState(false);

  const data = [
    {
      key: '1',
      transactionName: 'Venda do evento X',
      value: 'R$ 25,00',
      date: '01/10/2024',
      status: 'Agendado',
    },
    {
      key: '2',
      transactionName: 'Venda do evento Y',
      value: 'R$ 40,00',
      date: '01/10/2024',
      status: 'Planejado',
    },
    {
      key: '3',
      transactionName: 'Venda do evento Z',
      value: 'R$ 60,00',
      date: '02/10/2024',
      status: 'Pago',
    },
    {
      key: '4',
      transactionName: 'Venda do evento A',
      value: 'R$ 35,00',
      date: '02/10/2024',
      status: 'Pago',
    },
    {
      key: '5',
      transactionName: 'Venda do evento B',
      value: 'R$ 50,00',
      date: '03/10/2024',
      status: 'Previsto',
    },
    {
      key: '6',
      transactionName: 'Venda do evento C',
      value: 'R$ 70,00',
      date: '03/10/2024',
      status: 'Estornado',
    },
    {
      key: '7',
      transactionName: 'Venda do evento D',
      value: 'R$ 20,00',
      date: '04/10/2024',
      status: 'Pago',
    },
    {
      key: '8',
      transactionName: 'Venda do evento E',
      value: 'R$ 80,00',
      date: '04/10/2024',
      status: 'Previsto',
    },
    {
      key: '9',
      transactionName: 'Venda do evento F',
      value: 'R$ 100,00',
      date: '05/10/2024',
      status: 'Agendado',
    },
    {
      key: '10',
      transactionName: 'Venda do evento G',
      value: 'R$ 90,00',
      date: '05/10/2024',
      status: 'Pago',
    },
    {
      key: '11',
      transactionName: 'Venda do evento H',
      value: 'R$ 15,00',
      date: '06/10/2024',
      status: 'Agendado',
    },
    {
      key: '12',
      transactionName: 'Venda do evento I',
      value: 'R$ 45,00',
      date: '06/10/2024',
      status: 'Estornado',
    },
    {
      key: '13',
      transactionName: 'Venda do evento J',
      value: 'R$ 75,00',
      date: '07/10/2024',
      status: 'Pago',
    },
    {
      key: '14',
      transactionName: 'Venda do evento K',
      value: 'R$ 30,00',
      date: '07/10/2024',
      status: 'Previsto',
    },
  ];


  const statusColor = {
    Agendado: 'blue',
    Planejado: 'purple',
    Pago: 'green',
    Previsto: 'orange',
    Estornado: 'red',
  };

  const [searchText, setSearchText] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleSortChange = (value) => {
    setSortedInfo({
      order: value,
      columnKey: 'value',
    });
  };

  const filteredData = data.filter((item) =>
    item.transactionName.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      title: 'Nome da transação',
      dataIndex: 'transactionName',
      key: 'transactionName',
      render: (transactionName, { status }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {status === 'Planejado' || status === 'Estornado' ? <MoneyDown style={{ marginRight: 8 }} /> : <MoneyUp style={{ marginRight: 8 }} />}
          <span>{transactionName}</span>
        </div>
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      sorter: (a, b) => parseFloat(a.value.replace('R$ ', '').replace('.', '').replace(',', '.')) - parseFloat(b.value.replace('R$ ', '').replace('.', '').replace(',', '.')),
      sortOrder: sortedInfo.columnKey === 'value' ? sortedInfo.order : null,
      responsive: ["md"],
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      responsive: ["md"],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span className={`status-color ${status}`}>{status}</span>
      ),
    },
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const isMobile = useIsMobileHook(1100);
  const handleRowClick = (record) => {
    const id = record.key;
    if (expandedRowKeys.includes(id)) {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== id));
    } else {
      setExpandedRowKeys([id]);
    }
  };

  const expandedRowRender = (record) => (
    <div className="expanded">
      <div className="ticket-actions">
        <p><strong>Nome da transação:</strong> {record.transactionName}</p>
        <p><strong>Valor:</strong> {record.value}</p>
        <p><strong>Data:</strong> {record.date}</p>
        <p><strong>Status:</strong> <span className={`status-color ${record.status}`} >{record.status}</span></p>
      </div>
    </div>
  );

  return (
    <div className='Account' style={{ backgroundColor: "#f5f5f5" }}>
      <Helmet>
        <title>Clap Tickets - Conta</title>
      </Helmet>
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Content
          className="content-dashboard"
          style={{
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Breadcrumb
            className="breadcrumb-title"
          >
            <Breadcrumb.Item >Minha conta</Breadcrumb.Item>
          </Breadcrumb>

          <div className="container-account">
            <div className="top-account">
              <div className="card-balance">
                <span>Saldo da conta</span>
                <div className="value-balance">
                  <div className="balance-container">
                    {habilityBalance && <p className={`money ${habilityBalance ? "" : "hidden"}`}>R$ 2.773,85</p>}	
                    {!habilityBalance && <p className="empty-money">••••</p>}
                  </div>
                  {!habilityBalance && <EyeOutlined onClick={() => setHabilityBalance(true)} />}
                  {habilityBalance && <EyeInvisibleOutlined onClick={() => setHabilityBalance(false)} />}
                </div>
              </div>
              <button className="money-transfer">
                <Money /> Transferir saldo
              </button>
            </div>

            <div className="account-body">
              <div className="account-filters">
                <div className="title">
                  <h2>Histórico de movimentações</h2>
                </div>
                <div className="filters-div">
                  <Select
                    defaultValue="classificar por"
                    style={{ width: 200, marginBottom: 20 }}
                    onChange={handleSortChange}
                  >
                    <Select.Option value="ascend">Classificar por valor (crescente)</Select.Option>
                    <Select.Option value="descend">Classificar por valor (decrescente)</Select.Option>
                  </Select>
                  <Input.Search
                    placeholder="Pesquisar transação"
                    onSearch={handleSearch}
                    style={{ width: 300, marginBottom: 20 }}
                  />
                </div>
              </div>
              <Table
                columns={columns}
                dataSource={filteredData}
                pagination={{
                  pageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50'],
                  showQuickJumper: true,
                }}
                rowKey="key"
                expandable={{
                  expandedRowRender: isMobile ? expandedRowRender : undefined,
                  expandedRowKeys: expandedRowKeys,
                  onExpand: (expanded, record) => handleRowClick(record),
                  responsive: ["xl"],
                }}
              />
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  )
}

export default Account
